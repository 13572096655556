var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mb-4 white rounded-lg elevation-0"
  }, [_c('v-row', {
    staticClass: "px-4 pt-7"
  }, [_c('v-col', [_c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EquipmentIds).items.value,
      "item-text": "id",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EquipmentIds).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EquipmentIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EquipmentIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.EquipmentIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.EquipmentIds).value"
    }
  }), _c('CommonAutocomplete', {
    attrs: {
      "items": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).items.value,
      "item-text": "name",
      "item-value": "id",
      "clearable": "",
      "chips": "",
      "deletable-chips": "",
      "small-chips": "",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "loading": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).isLoading.value,
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PersonIds), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.PersonIds).value"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Name).label,
      "clearable": "",
      "outlined": "",
      "dense": "",
      "prepend-inner-icon": _vm.icons.mdiMagnify
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Name).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Name), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.Name).value"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SerialNumber).label,
      "clearable": "",
      "outlined": "",
      "dense": "",
      "prepend-inner-icon": _vm.icons.mdiMagnify
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SerialNumber).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.SerialNumber), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.SerialNumber).value"
    }
  })], 1), _c('v-col', [_c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PlannedReturnFrom).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PlannedReturnFrom).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PlannedReturnFrom), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.PlannedReturnFrom).value"
    }
  }), _c('CommonDateInput', {
    attrs: {
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PlannedReturnTo).label
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PlannedReturnTo).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.PlannedReturnTo), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.PlannedReturnTo).value"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Type).label,
      "clearable": "",
      "outlined": "",
      "dense": "",
      "prepend-inner-icon": _vm.icons.mdiMagnify
    },
    model: {
      value: _vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Type).value,
      callback: function callback($$v) {
        _vm.$set(_vm.functions.getFilterByKey(_vm.constants.FILTER_FIELD_KEY.Type), "value", $$v);
      },
      expression: "functions.getFilterByKey(constants.FILTER_FIELD_KEY.Type).value"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }